import { AssetContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';

import './redirectUrl.scss';

import SearchUrlBreadCrumbs from './SearchUrlBreadCrumbs/SearchUrlBreadCrumbs';

/* eslint-disable react/jsx-no-useless-fragment */
export interface RedirectUrlProps {
  className?: string;
  showDocumentIcon?: any;
}
export const RedirectUrl: React.FC<RedirectUrlProps> = ({ className, showDocumentIcon }) => {
  const { docSolrAsset } = useContext(AssetContext);
  const [docSolrAssetData, setDocSolrAsset] = useState(docSolrAsset?.dataAsset);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const limitUrllength = docSolrAssetData?.url || '';
  const toggleUrlLength =
    limitUrllength?.length > 51 ? docSolrAssetData?.url?.slice(0, 51) + '...' : docSolrAssetData?.url;
  return (
    <>
      {/* condition for null values */}
      {((docSolrAssetData?.content_source === 'SharePoint' || docSolrAssetData?.content_source === 'Ops KM Assets') &&
        (!docSolrAssetData?.source_filename || docSolrAssetData?.source_filename === null)) ||
      ((docSolrAssetData?.content_source === 'Amgen RIM' ||
        docSolrAssetData?.content_source === 'CDOCS' ||
        docSolrAssetData?.content_source === 'eTMF') &&
        (!docSolrAssetData?.document_number || docSolrAssetData?.document_number === null)) ? (
        // <div className="redirect-urls">
        //   <p title={docSolrAssetData?.url} className="pl-3 ellipses">
        //     {toggleUrlLength}
        //   </p>
        // </div>
        <div className="link-doc-icon-breadcrumb">
          {showDocumentIcon ? (
            <span className="doc-tag-breadcrumb">Document</span>
          ) : (
            <span className="link-tag-breadcrumb">Link</span>
          )}
          <Breadcrumb className="redirect-urls" title={docSolrAssetData?.url}>
            <p title={docSolrAssetData?.url} className="custom-ellipse ellipses">
              {toggleUrlLength}
            </p>
            {docSolrAssetData.pageNo !== '' && docSolrAssetData.pageNo !== null && abTestingGroup ? (
              <Breadcrumb.Item href="#">{` > (Page: ${docSolrAssetData.pageNo})`}</Breadcrumb.Item>
            ) : (
              <></>
            )}
          </Breadcrumb>
        </div>
      ) : (
        // showing breadcrumb
        <div className="link-doc-icon-breadcrumb">
          {showDocumentIcon ? (
            <span className="doc-tag-breadcrumb">Document</span>
          ) : (
            <span className="link-tag-breadcrumb">Link</span>
          )}
          <SearchUrlBreadCrumbs docSolrAssetData={docSolrAssetData} toggleUrlLength={toggleUrlLength} />
        </div>
      )}
    </>
  );
};
